<template>
 <div>
  <b-card bg-variant="light">
    <b-form-group 
      label-cols-lg="3"
      label="Collection"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >
      <b-form-group
        label="Name:"
        label-for="nested-name"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-name"
          v-model="collection.name"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Access Permission:"
        label-cols-sm="3"
        label-align-sm="right"
        class="mb-0"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          class="pt-2"
          :options="['private_access', 'protected_access', 'public_access']"
          :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
      </b-form-group>
    </b-form-group>

    <b-button block variant="primary" @click="callApi">Add Collection</b-button>
  </b-card>
 </div>
</template>

<script>
import {http_v1} from '@/axios'

export default {
  data () {
    return {
      collection:{
        name: '',
        access_permission: 'private_access'
      },
    }
  },
  methods: {
    callApi () {
      http_v1.post (
        'api/admin/v1/collections', {
          ...this.collection
        }
      ).then(() => {
        // this.$router.push( {name: 'CollectionIndex'})
        this.$router.go(-1)
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
  },
}
</script>